<template>
  <div class="view pa24">
    <div class="view mr mb20">
      <span style="color: #8d97ad; font-size: 14px" v-if="userInfo.roleId">设置部门和管理员流程:①请先在下方成员列表中选择相应员工,点击“编辑" , 设置为管理员------②在“部门设置"中,新增部门,选择相应管理员------③如需设置管理员相关权限,可在"管理员列表”里编辑修改管理员权限</span
      >
    </div>
    <el-row :gutter="24">
      <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
        <div class="d-flex align-items-center">
          <el-input
             v-model="searchKey"
             class="mb10"
             style="width: 240px"
             placeholder="请输入员工姓名"
          />
          <el-button class="ml10 mb10" type="primary" @click="getCorporator">搜索</el-button>
          <!-- <el-button class="ml10 mb10">导出</el-button> -->
        </div>
        <div class="d-flex align-items-center">
          <el-button type="primary" 
                     class="mb10" 
                     v-if="userInfo.roleId || jurisdictionA || userInfo.main" 
                    @click="sectorSetup">部门设置</el-button>
          <el-button type="primary" 
                     class="mb10" 
                     v-if="userInfo.roleId || jurisdictionB || userInfo.main" 
                     @click="AdministratorList">管理员列表</el-button>
        </div>
      </el-col>
      
    </el-row>
    <commonTable :tableData="tableData" 
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column prop="jobNumber" width="100px" align="center" label="工号" />
        <el-table-column prop="employeeName" align="center" label="姓名" />
        <el-table-column
          prop="position"
          align="center"
          label="职位"
          show-overflow-tooltip
        />
        <el-table-column prop="departmentName" align="center" label="部门" />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="phone" align="center" label="手机" />
        <el-table-column prop="character" width="100px" align="center" label="角色" />
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column prop="monopolismCus" align="center" label="独占客户数" />
        <el-table-column prop="dealCus" align="center" label="成单客户数" />
        <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="edit_tap(scope.row)">编辑</el-button>
              <el-button type="text" @click="detall_tap(scope.row.jobNumber)">详情</el-button>
            </template>
            <!-- <el-button type="text" style="color: #4484fd">编辑</el-button> -->
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="编辑成员"
      center
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="25%"
    >
      <!-- <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      > -->
      <el-form
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      >
        <el-form-item label="工号">
          <el-input
            readonly
            style="width: 240px"
            v-model="memberInfo.jobNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input style="width: 240px" v-model="memberInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-input style="width: 240px" v-model="memberInfo.position"></el-input>
        </el-form-item>
        <el-form-item label="微信号">
          <el-input
            style="width: 240px"
            v-model="memberInfo.personalWx"
          ></el-input>
        </el-form-item>
        <el-form-item label="名片展示手机号">
          <el-input
            type="number"
            maxlength="11"
            readonly 
            style="width: 240px"
            v-model="memberInfo.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-select
            v-model="memberInfo.departmentId"
            style="width: 240px"
            :disabled="setDepartment"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in departmentList"
              :key="index"
              :label="item.name"
              :value="item.departmentId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-if="memberInfo.userType !== 2">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="setAdministrator"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="setAdministrator">管理员</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-else>
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="memberInfo.userType == 2"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="memberInfo.userType == 2">管理员</el-radio>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirm_tap"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchCorporator,queryPageEmployee,queryEmployee,queryPageDepartment,updateEmployee } from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils/index";
import { mapState } from 'vuex';
export default {
  name: "enterpriseMember",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [],
      departmentOptions: [{ value: "1", lable: "普通成员" }],
      dialogVisible: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      searchKey:'', //关键字
      jurisdictionA:'',
      jurisdictionB:'',
      jurisdictionC:'',
      jurisdictionD:'',
      jurisdictionF:'',
      userInfo: this.$store.state.loginRoot.userInfo,
      departmentList:[],
      memberInfo:{
        position:'',
        phone:'',
        personalWx:'',
        jobNumber:'',
        position:'',
        departmentId:'',
        userType:'',
        queryType:'', //请求类型
        
      },
      setAdministrator:false, // 是否可设置管理员
      setDepartment:false,// 是否可设置部门
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdictionA = val.some(item => ['0210','02100','02101'].includes(item))//部门管理
      this.jurisdictionB = val.includes('0211')//管理员设置
      this.jurisdictionC = val.includes('0212')//成员设置
      this.jurisdictionD = val.some(item => ['02100'].includes(item))//部门管理(自己)
      this.jurisdictionF = val.some(item => ['02101'].includes(item))//部门管理(所有)      
      if(this.jurisdictionD){
        this.queryType = 'section'
        if(this.jurisdictionF){
          this.queryType = 'all'
        }
        this.getCorporator()
      }
      if(this.jurisdictionF){
        this.queryType = 'all'
        this.getCorporator()
      }
    },
    jurisdictionB(val){
      if(this.userInfo.roleId){
        this.setAdministrator = false 
      }else{
        this.setAdministrator = val ? false : true
      } 
      
    },
    jurisdictionF(val){
      if(this.userInfo.roleId){
        this.setDepartment = false 
      }else{
        this.setDepartment = val ? false : true
      } 
      
    }
  },
  filters: {
    dateFilter(val) {
      console.log(val);
    },
  },
  mounted() {
    this.getCorporator();
  },
  methods: {
    //获取企业成员
    getCorporator(Type) {
      let data = {
        pageNum:this.pageNum, 
        pageSize:this.pageSize,
        name:this.searchKey,
        queryType:this.$store.state.loginRoot.userInfo.roleId ? 'all' : this.queryType ? this.queryType : 'single'
      }
      queryPageEmployee(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list;
          this.total = res.data.pageInfo.total
          this.tableData.forEach((v) => {
            v.createTime = getDataTimeSec(v.createTime);
            if(v.userType == 0 ){
              v.character = '员工'
            }else if(v.userType == 1){
              v.character = '管理员'
            }else{
              v.character = 'BOSS'
            }
          });
        })
        .catch((err) => {
          this.tableData = []
          this.loading = false;
          console.log(err);
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getCorporator();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getCorporator();
    },
    //请求员工详情
    async queryEmployee(id){
      let data = {
        empId: id
      }
      let result = await queryEmployee(data)
      if(result.data.routers){
        result.data.routers = JSON.parse(result.data.routers || [])
      }
      this.memberInfo = result.data
      console.log(this.memberInfo,'info')
    },
    //请求部门信息
    getPageDepartment() {
      let data = {
        pageSize: 999,
      };
      queryPageDepartment(data)
      .then((res) => {
        this.departmentList = res.data.pageInfo.list || "";
      })
      .catch((err) => {
        this.departmentList = [];
      });
    },

    detall_tap(id){
      this.$router.push('./memberDetall?id=' + id)
    },
    edit_tap(item){
      if(!this.userInfo.roleId){
        if(item.userType == 0 ){
          if(!this.jurisdictionC){
            return this.$message({
              message: "暂无权限",
              type: "warning",
            });
          }

        }else if(item.userType == 1){
          if(!this.jurisdictionB){
            return this.$message({
              message: "暂无权限",
              type: "warning",
            });
          }
        }else if(item.userType == 2 ){
          return this.$message({
            message: "BOSS信息无法修改",
            type: "warning",
          });
        }
      }
      
      
      if(this.jurisdictionC || this.userInfo.roleId ){
        this.queryEmployee(item.empId)
        this.getPageDepartment()
        this.dialogVisible = true
      }else{
        return this.$message({
          message: "暂无权限",
          type: "warning",
        });
      }
      
    },
    sectorSetup(){
      this.$router.push('/sectorSetup')
    },
    AdministratorList(){
      this.$router.push('/AdministratorList')
    },
    affirm_tap(){
      if(this.memberInfo.userType == 0){
       this.memberInfo.routers = []
      }else{
       this.memberInfo.routers = ['022','0213','04','040','041','08']
      }
      debugger
      updateEmployee(this.memberInfo)
      .then(res=>{
        this.dialogVisible = false
        this.getCorporator()
      })
      
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>